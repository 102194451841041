/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { useModal } from '../zustand/modalStore.js';
import { fetchComponents, pushComponents, updateComponents, removeComponents } from '../redux/actions/componentsActions';
import {
    Container,
    Row,
} from 'reactstrap';

import Breadcrumb from '../components/Common/Breadcrumb.js';
import Notifications from '../components/Extra/Notifications.js';
import Materials from '../components/Components/Materials.js';
import Model from '../components/Components/Model.js';
import PrintingSize from '../components/Components/PrintingSize.js';
import PrintingCost from '../components/Components/PrintingCost.js';
import Quantity from '../components/Components/Quantity.js';
import Options from '../components/Components/Options.js';
import ProductionTimeline from '../components/Components/ProductionTimeline.js';
import ArtworkService from '../components/Components/ArtworkService.js';
import FileStorage from '../components/Components/FileStorage.js';
import BindingType from '../components/Components/BindingType.js';
import PackagingType from '../components/Components/PackagingType.js';

import ModalWithCallback from '../components/Common/ModalWithCallback.js';
import client from '../feathers.js';

const ComponentsCreate = (props) => {
    const { role } = props;
    const [ message, setMessage ] = useState('');
    const [ color, setColor ] = useState('');
    const [ isOpen, setIsOpen ] = useState(false);
    const [ category, setCategory ] = useState();
    const [ componentsTypeId, setComponentsTypeId ] = useState();
    const [ componentId, setComponentId ] = useState();
    const [ duplicate, setDuplicate ] = useState();
    const { search } = useLocation();

    const {
        isOpen: modalIsOpen,
        title: modalTitle,
        content: modalContent,
        callback: modalCallback,

        actions: modalActions,
    } = useModal();

    useEffect(() => {
        const params = new URLSearchParams(search);
        setCategory(params.get('category'));
        setComponentsTypeId(params.get('categoryId'));
        setComponentId(params.get('id'));

        const isDuplicate = params.get('duplicate') === 'true';
        setDuplicate(isDuplicate);
    }, []);

    const notificationOpen = (bool, color, message) => {
        modalActions.setIsOpen(bool);
        modalActions.setTitle('Alert');
        modalActions.setContent(message);
        modalActions.setCallback(null);
        // setIsOpen(bool);
        // setColor(color);
        // setMessage(message);
    };

    const onSubmit = async(value) => {
        
        if (value.dynamicPriceId === 'Please Select') {
            value.dynamicPriceId = undefined;
        }
        if (value.data?.printingCost?.length > 0) {
            value.data.printingCost = value.data.printingCost.map((x) => {
                if (x.dynamicPriceId === 'Please Select') {
                    return {
                        ...x,
                        dynamicPriceId: undefined,
                    };
                } else {
                    return x;
                }
            });
        }
        if (value.dynamicPriceId === 'Please Select') {
            value.dynamicPriceId = undefined;
        }
        if (value._id) {
            await client.authenticate()
                .then(()=>{
                    return client.service('components').update(value._id, value);
                })
                .then((res) => {
                    modalActions.setIsOpen(true);
                    modalActions.setTitle('Updated Component');
                    modalActions.setContent(`Component ${value.name} Updated Successfully!`);
                    modalActions.setCallback(undefined);
                })
                .catch((err)=>{
                    if(err.name === 'NotAuthenticated'){
                        notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                    }else{
                        notificationOpen(true, 'danger', err.message);
                    }
                });
        } else {
            await client.authenticate()
                .then(()=>{
                    return client.service('components').create({
                        ...value,
                        _id: undefined,
                    });
                })
                .then((res) => {
                    modalActions.setIsOpen(true);
                    modalActions.setTitle('Added Component');
                    modalActions.setContent(`Component ${value.name} Added Successfully!`);
                    modalActions.setCallback(undefined);
                    // props.history.push(`/${props.role}/components`);
                })
                .catch((err)=>{
                    if(err.name === 'NotAuthenticated'){
                        notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                    }else{
                        notificationOpen(true, 'danger', err.message);
                    }
                });
        }
    };

    const renderComponentByCategory = () => {
        switch(category) {
            case 'Material': {
                return (
                    <Materials
                        componentId={componentId}
                        componentTypeName={category}
                        componentsTypeId={componentsTypeId}
                        notificationOpen={notificationOpen} 
                        onSubmit={onSubmit}
                        pushComponents={props.pushComponents}
                        componentsData={props.componentsData}
                        currency={props.currency}
                        role={props.role}
                        userId={props.userId}
                        dynamicPriceData={props.dynamicPriceData}
                        duplicate={duplicate}
                    />
                );
            }
            case 'Model': {
                return (
                    <Model
                        componentId={componentId}
                        componentTypeName={category}
                        componentsTypeId={componentsTypeId}
                        notificationOpen={notificationOpen} 
                        onSubmit={onSubmit}
                        pushComponents={props.pushComponents}
                        componentsData={props.componentsData}
                        currency={props.currency}
                        role={props.role}
                        userId={props.userId}
                        dynamicPriceData={props.dynamicPriceData}
                        duplicate={duplicate}
                    />
                );
            }
            case 'Printing Size': {
                return (
                    <PrintingSize
                        componentId={componentId}
                        componentTypeName={category}
                        componentsTypeId={componentsTypeId}
                        notificationOpen={notificationOpen} 
                        onSubmit={onSubmit}
                        pushComponents={props.pushComponents}
                        componentsData={props.componentsData}
                        currency={props.currency}
                        role={props.role}
                        userId={props.userId}
                        dynamicPriceData={props.dynamicPriceData}
                        duplicate={duplicate}
                    />
                );
            }
            case 'Printing Cost': {
                return (
                    <PrintingCost
                        componentId={componentId}
                        componentTypeName={category}
                        componentsTypeId={componentsTypeId}
                        notificationOpen={notificationOpen} 
                        onSubmit={onSubmit}
                        pushComponents={props.pushComponents}
                        componentsData={props.componentsData}
                        currency={props.currency}
                        role={props.role}
                        userId={props.userId}
                        dynamicPriceData={props.dynamicPriceData}
                        duplicate={duplicate}
                    />
                );
            }
            case 'Quantity': {
                return (
                    <Quantity
                        componentId={componentId}
                        componentTypeName={category}
                        componentsTypeId={componentsTypeId}
                        notificationOpen={notificationOpen}
                        onSubmit={onSubmit}
                        pushComponents={props.pushComponents}
                        componentsData={props.componentsData}
                        currency={props.currency}
                        role={props.role}
                        userId={props.userId}
                        dynamicPriceData={props.dynamicPriceData}
                        duplicate={duplicate}
                    />
                );
            }
            case 'Production Timeline': {
                return (
                    <ProductionTimeline
                        componentId={componentId}
                        componentTypeName={category}
                        componentsTypeId={componentsTypeId}
                        notificationOpen={notificationOpen}
                        onSubmit={onSubmit}
                        pushComponents={props.pushComponents}
                        componentsData={props.componentsData}
                        currency={props.currency}
                        role={props.role}
                        userId={props.userId}
                        dynamicPriceData={props.dynamicPriceData}
                        duplicate={duplicate}
                    />
                );
            }
            case 'Artwork Service': {
                return (
                    <ArtworkService
                        componentId={componentId}
                        componentTypeName={category}
                        componentsTypeId={componentsTypeId}
                        notificationOpen={notificationOpen}
                        onSubmit={onSubmit}
                        pushComponents={props.pushComponents}
                        componentsData={props.componentsData}
                        currency={props.currency}
                        role={props.role}
                        userId={props.userId}
                        dynamicPriceData={props.dynamicPriceData}
                        duplicate={duplicate}
                    />
                );
            }
            case 'File Storage': {
                return (
                    <FileStorage
                        componentId={componentId}
                        componentTypeName={category}
                        componentsTypeId={componentsTypeId}
                        notificationOpen={notificationOpen}
                        onSubmit={onSubmit}
                        pushComponents={props.pushComponents}
                        componentsData={props.componentsData}
                        currency={props.currency}
                        role={props.role}
                        userId={props.userId}
                        dynamicPriceData={props.dynamicPriceData}
                        duplicate={duplicate}
                    />
                );
            }
            case 'Binding Type': {
                return (
                    <BindingType
                        componentId={componentId}
                        componentTypeName={category}
                        componentsTypeId={componentsTypeId}
                        notificationOpen={notificationOpen}
                        onSubmit={onSubmit}
                        pushComponents={props.pushComponents}
                        componentsData={props.componentsData}
                        currency={props.currency}
                        role={props.role}
                        userId={props.userId}
                        dynamicPriceData={props.dynamicPriceData}
                        duplicate={duplicate}
                    />
                );
            }
            case 'Packaging': {
                return (
                    <PackagingType
                        componentId={componentId}
                        componentTypeName={category}
                        componentsTypeId={componentsTypeId}
                        notificationOpen={notificationOpen}
                        onSubmit={onSubmit}
                        pushComponents={props.pushComponents}
                        componentsData={props.componentsData}
                        currency={props.currency}
                        role={props.role}
                        userId={props.userId}
                        dynamicPriceData={props.dynamicPriceData}
                        duplicate={duplicate}
                    />
                );
            }
            default: {
                if(category && componentsTypeId ) {
                    return (
                        <Options
                            componentId={componentId}
                            componentTypeName={category}
                            componentsTypeId={componentsTypeId}
                            notificationOpen={notificationOpen}
                            onSubmit={onSubmit}
                            pushComponents={props.pushComponents}
                            componentsData={props.componentsData}
                            currency={props.currency}
                            role={props.role}
                            userId={props.userId}
                            dynamicPriceData={props.dynamicPriceData}
                            duplicate={duplicate}
                        />
                    );
                }
            }
        }
    };

    return (  
        <>
            {(window.self === window.top) && (
                <div className='header' style={{height: 64}}>
                    <span className='mask' style={{backgroundColor: '#fff'}} />
                </div>
            )}
            <Container fluid>
                <Row className='m-1 mt-3'>
                    <h2 className='mr-4 mb-0'>{(componentId) ? 'Edit Component' : 'Create Component'}</h2>
                </Row>
                <Breadcrumb
                    items={[
                        {title: 'Components Category', link: 'components'},
                        {title: 'Components Listing', link: `components-list?category=${category}&categoryId=${componentsTypeId}`},
                        {title: 'Components'},
                    ]}
                />
                {renderComponentByCategory()}
                <Notifications 
                    isOpen={isOpen} 
                    handleOpen={notificationOpen} 
                    message={message} 
                    color={color}
                />
                <ModalWithCallback
                    isOpen={modalIsOpen}
                    toggle={() => modalActions.toggle()}
                    title={modalTitle}
                    content={modalContent}
                    callback={modalCallback}
                />
            </Container>
            
        </>
    );
};
 

const mapStateToProps = state => ({
    dynamicPriceData: state.dynamicPrice.data, 
    role: state.role.details.user?state.role.details.user.role:'',
    userId: state.role.details.user?state.role.details.user._id:'',
});
  
const mapDispatchToProps = {
    fetchComponents: fetchComponents,
    pushComponents: pushComponents,
    updateComponents: updateComponents,
    removeComponents: removeComponents
};
export default connect(mapStateToProps, mapDispatchToProps)(ComponentsCreate);