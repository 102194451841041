import React, { useState, useEffect } from 'react';
import ObjectId from 'bson-objectid';
import { connect } from 'react-redux';
import { fetchComponents, pushComponents, updateComponents, removeComponents } from '../redux/actions/componentsActions.js';
import { fetchComponentsType } from '../redux/actions/componentsTypeActions.js';
import getSymbolFromCurrency from 'currency-symbol-map';
import CreateComponentsTypeModal from '../components/Components/CreateComponentsTypeModal.js';
import { useReport } from '../zustand/reportStore.js';
import { useModal } from '../zustand/modalStore.js';
import ModalWithCallback from '../components/Common/ModalWithCallback.js';
import ReportPagination from '../components/Common/ReportPagination.js';

import { ReactComponent as DeleteIcon } from '../assets/img/icons/common/trash.svg';
import { ReactComponent as EditIcon } from '../assets/img/icons/common/edit.svg';
import { ReactComponent as ViewIcon } from '../assets/img/icons/common/eye.svg';
import { ReactComponent as SearchIcon } from '../assets/img/icons/common/search.svg';

// reactstrap components
import {
    Button,
    // Badge,
    Card,
    CardBody,
    CardFooter,
    Input,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Table,
    Container,
    Row,
    Col,
    InputGroup,
} from 'reactstrap';


import Notifications from '../components/Extra/Notifications.js';

import client from '../feathers';
import axios from 'axios';

const Components = (props) => {
    const { componentsTypeData, userId, components, role, notificationOpen, packageDetails } = props;
    const [ message, setMessage ] = useState('');
    const [ color, setColor ] = useState('');
    const [ isNotificationOpen, setIsNotificationOpen ] = useState(false);
    
    const [ isOpenComponentTypeCreate, setIsOpenComponentTypeCreate ] = useState(false);
    const [ existingComponentsType, setExistingComponentsType ] = useState();

    const [regexComponentTypeName, setRegexComponentTypeName] = useState('');

    const {
        componentTypeCurrentIndex,
        componentTypePerPage,
        componentTypeMaxIndex,
        componentTypeData,

        actions: reportActions,
    } = useReport();

    const {
        isOpen: modalIsOpen,
        title: modalTitle,
        content: modalContent,
        callback: modalCallback,

        actions: modalActions,
    } = useModal();
    

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        fetchData();
    }, [regexComponentTypeName, componentTypeCurrentIndex, componentTypePerPage]);
  
    const fetchData = () => {
        if(userId){
            client.authenticate()
                .then(()=>{
                    let query = {
                        orRegex: { name: regexComponentTypeName },
                        $or: [{ userId: new ObjectId(userId) }, { userId: null }],
                        $limit: componentTypePerPage,
                        $skip: componentTypeCurrentIndex * componentTypePerPage,
                        $sort: { createdAt: 1 },
                    };
                    if(role === 'superadmin') {
                        query = {
                            orRegex: { name: regexComponentTypeName },
                            $or: [{ userId: new ObjectId(userId) }, { userId: null }],
                            name: { $nin: ['Production Timeline', 'Artwork Service', 'File Storage']},
                            $limit: componentTypePerPage,
                            $skip: componentTypeCurrentIndex * componentTypePerPage,
                            $sort: { createdAt: 1 },
                        };
                    }
                    return client.service('componentsType').find({
                        query
                    });
                })
                .then((res) => {
                    reportActions.setComponentTypeMaxIndex(Math.floor(res.total / componentTypePerPage));
                    if(res.data?.length > 0){
                        res.data = res.data.filter(x => {
                            if (x.name === 'Packaging') {
                                if (userId.toString() === '66461756bb3342542e5e46aa' || 
                                    userId.toString() === '666bfd58fa5b75edc0014fec' || 
                                    userId.toString() === '6672593f3cdde81e9d675955') {
                                    return x;
                                } else {
                                    return null;
                                }
                            } else {
                                return x;
                            }
                        });
                    }
                    reportActions.setComponentTypeData(res.data);
                });
        }
    };

    const deleteComponentType = (id) => {
        if(userId){
            client.authenticate()
                .then(() => {
                    return client.service('componentsType').remove(id);
                })
                .then((res) => {
                    fetchData();
                });
        }
    };

    const getMaxComponentCount = (name) => {
        switch (name) { 
            case 'Binding Type': {
                return packageDetails.componentRestrictions.bindingType;
            } case 'Material': {
                return packageDetails.componentRestrictions.material;
            } case 'Printing Size': {
                return packageDetails.componentRestrictions.printingSize;
            } case 'Printing Cost': {
                return packageDetails.componentRestrictions.printingCost;
            } case 'Quantity': {
                return packageDetails.componentRestrictions.quantity;
            } case 'Production Timeline': {
                return packageDetails.componentRestrictions.productionTimeline;
            } case 'Artwork Service': {
                return packageDetails.componentRestrictions.artwork;
            } case 'File Storage': {
                return packageDetails.componentRestrictions.fileStorage;
            }
            default: {
                return packageDetails.componentRestrictions.additionalComponent;
            }
        }
    };


    const addAsterisk = (name) => {
        switch (name) { 
            case 'Material': {
                return '*';
            } case 'Printing Size': {
                return '*';
            } case 'Printing Cost': {
                return '*';
            } case 'Quantity': {
                return '*';
            }
            default: {
                return '';
            }
        }
    };

    return (  
        <>
            {/* eslint-disable-next-line no-undef */}
            {(window.self === window.top) && (
                <div className='header' style={{height: 64}}>
                    <span className='mask' style={{backgroundColor: '#fff'}} />
                </div>
            )}
            <Container fluid>
                <Row className='m-1 mt-3'>
                    <h2 className='mr-4 mb-0'>Component Category</h2>
                </Row>
                <Card className='mt-3'>
                    <CardBody>
                        <Row className='pt-0 pb-3 d-flex align-items-center justify-content-between'>
                            <Col md='4'>
                                <InputGroup className='input-group-alternative d-flex align-items-center'>
                                    <SearchIcon height={20} className='mx-1'/>
                                    <Input
                                        placeholder='Search Product Name...'
                                        className='w-auto d-inline-block mr-2'
                                        type='text'
                                        bsSize='sm'
                                        size={20}
                                        value={regexComponentTypeName}
                                        onChange={(e) => setRegexComponentTypeName(e.target.value)}
                                    />
                                </InputGroup>
                                
                            </Col>
                            <Col md='7' className='text-right'>
                                <Button
                                    color='primary'
                                    onClick={() => [
                                        setIsOpenComponentTypeCreate(true),
                                        setExistingComponentsType(undefined),
                                    ]}
                                >
                                    <span className='btn-inner--text'>+ Component Category</span>
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <div className='col'>
                                <Card>
                                    <div className='table-responsive mac-scrollbar'>
                                        <Table className='align-items-center table-flush'>
                                            <thead style={{ height: 50}}>
                                                <tr>
                                                    <th scope='col'>
                                                        <h5 className='m-0'>Name</h5>
                                                    </th>
                                                    <th scope='col'>
                                                        <h5 className='m-0'>Description</h5>
                                                    </th>
                                                    <th scope='col'>
                                                        <h5 className='m-0'>Components</h5>
                                                    </th>
                                                    <th scope='col' className='text-right'>
                                                        <h5 className='m-0'>Action</h5>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {componentTypeData.length > 0? (
                                                    componentTypeData.map((v, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>
                                                                    <a href={`/${props.role}/components-list?category=${v.name}&categoryId=${v._id}`}>
                                                                        <h5 className='m-0'>{`${v.name}${(addAsterisk(v.name))}`}</h5>
                                                                    </a>
                                                                </td>
                                                                <td>{v.description || '-'}</td>
                                                                <td>{(packageDetails && role !== 'superadmin') ? `${v.componentsCount}/${getMaxComponentCount(v.name)}`: v.componentsCount}</td>
                                                                <td className='text-right'>
                                                                    <Button
                                                                        color='grey'
                                                                        className='btn-square'
                                                                        onClick={() => {
                                                                            props.history.push(`/${props.role}/components-list?category=${v.name}&categoryId=${v._id}`);
                                                                        }}
                                                                    >
                                                                        <ViewIcon height={15} width={15}/>
                                                                    </Button>
                                                                    {!v.isCompulsory && (
                                                                        <>
                                                                            <Button
                                                                                color='grey'
                                                                                className='btn-square'
                                                                                onClick={() => {
                                                                                    setExistingComponentsType(v); 
                                                                                    setIsOpenComponentTypeCreate(true);
                                                                                }}
                                                                            >
                                                                                <EditIcon height={15} width={15}/>
                                                                            </Button>
                                                                    
                                                                            <Button
                                                                                color='grey'
                                                                                className='btn-square'
                                                                                onClick={() => [
                                                                                    modalActions.setIsOpen(true),
                                                                                    modalActions.setTitle('Delete Component Category'),
                                                                                    modalActions.setContent('Are you sure you want to delete this Component Category?'),
                                                                                    modalActions.setCallback(() => deleteComponentType(v._id)),
                                                                                ]}
                                                                            >
                                                                                <DeleteIcon height={15} width={15}/>
                                                                            </Button>
                                                                        </>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                ): (
                                                    <tr>
                                                        <td>
                                                            <span style={{padding: '0px 10px'}}>No Record Found!</span>
                                                        </td>
                                                    </tr>
                                                )
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                    <CardFooter className='py-4'>
                                        <div className='d-flex justify-content-between'>
                                            <div>
                                                <UncontrolledDropdown>
                                                    <DropdownToggle
                                                        className='btn-icon-only text-black p-0'
                                                        style={{width: 100}}
                                                        caret
                                                        href='#pablo'
                                                        role='button'
                                                        size='sm'
                                                        color=''
                                                        onClick={(e) => e.preventDefault()}
                                                    >
                                                        {componentTypePerPage} / page
                                                    </DropdownToggle>
                                                    <DropdownMenu className='w-100'>
                                                        <DropdownItem 
                                                            value={30}
                                                            onClick={() => {
                                                                reportActions.setComponentTypePerPage(30);
                                                                fetchData();
                                                            }
                                                            }
                                                        >
                                                            30 / page
                                                        </DropdownItem>
                                                        <DropdownItem 
                                                            value={50} 
                                                            onClick={() => {
                                                                reportActions.setComponentTypePerPage(50);
                                                                fetchData();
                                                            }}
                                                        >
                                                            50 / page
                                                        </DropdownItem>
                                                        <DropdownItem 
                                                            value={100} 
                                                            onClick={() => {
                                                                reportActions.setComponentTypePerPage(100);
                                                                fetchData();
                                                            }}
                                                        >
                                                            100 / page
                                                        </DropdownItem>
                                                        <DropdownItem 
                                                            value={150} 
                                                            onClick={() => {
                                                                reportActions.setComponentTypePerPage(150);
                                                                fetchData();
                                                            }}
                                                        >
                                                            150 / page
                                                        </DropdownItem>
                                                        <DropdownItem 
                                                            value={200} 
                                                            onClick={() => {
                                                                reportActions.setComponentTypePerPage(200);
                                                                fetchData();
                                                            }}
                                                        >
                                                            200 / page
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </div>
                                            
                                            <nav aria-label='...'>
                                                <ReportPagination
                                                    setIndex={(data) => reportActions.setComponentTypeCurrentIndex(data)}
                                                    currentIndex={componentTypeCurrentIndex}
                                                    maxIndex={componentTypeMaxIndex}
                                                />
                                            </nav>
                                        </div> 
                                    </CardFooter>
                                </Card>
                            </div>
                        </Row>
                    </CardBody>
                </Card> 
            </Container>

            <Notifications 
                isOpen={isNotificationOpen} 
                handleOpen={notificationOpen} 
                message={message} 
                color={color}
            />

            <ModalWithCallback
                isOpen={modalIsOpen}
                toggle={() => modalActions.toggle()}
                title={modalTitle}
                content={modalContent}
                callback={modalCallback}
            />
            <CreateComponentsTypeModal
                existingComponentsType={existingComponentsType}
                isOpen={isOpenComponentTypeCreate}
                toggle={() => setIsOpenComponentTypeCreate(!isOpenComponentTypeCreate)}
                reFetchData={() => fetchData()}
            />
        </>
    );
};
 

const mapStateToProps = state => ({
    userId: state.role.details.user?state.role.details.user._id:'',
    currency: state.role.details.user?state.role.details.user.currency?getSymbolFromCurrency(state.role.details.user.currency):'rm':'rm',
    dynamicPriceData: state.dynamicPrice.data,
    componentsTypeData: state.componentsType.data,
    components: state.components.data,
    role: state.role.details.user?state.role.details.user.role:'',
    userInfo: state.role.details.user?state.role.details.user:{},
    packageDetails: state.packageDetails.data,
});

const mapDispatchToProps = {
    fetchComponents: fetchComponents,
    pushComponents: pushComponents,
    updateComponents: updateComponents,
    removeComponents: removeComponents,
    fetchComponentsType: fetchComponentsType,
};
export default connect(mapStateToProps, mapDispatchToProps)(Components);