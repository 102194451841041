import React, { useEffect, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import ObjectId from 'bson-objectid';
import { useLocation } from 'react-router-dom';
import { DragDropContext } from 'react-beautiful-dnd';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { connect } from 'react-redux';
import { pushProducts } from '../redux/actions/productsActions';
import { fetchComponents, pushComponents, updateComponents, removeComponents } from '../redux/actions/componentsActions.js';
import { fetchComponentsType } from '../redux/actions/componentsTypeActions.js';
import getSymbolFromCurrency from 'currency-symbol-map';
import ModalWithCallback from '../components/Common/ModalWithCallback.js';
import Breadcrumb from '../components/Common/Breadcrumb.js';
import { getQuantities } from '../utils/productComponentUtil.js';
import { useModal } from '../zustand/modalStore.js';

import client from '../feathers.js';
import env from '../env.js';
// reactstrap components
import {
    Button,
    Form,
    Row,
    Col,
    Card,
    CardBody,
    Container,
} from 'reactstrap';
import Notifications from '../components/Extra/Notifications.js';
import Dropdown from '../components/Common/Dropdown.js';
import BookletComponentStructure from '../components/Products/ProductsByComponents/Structure/BookletComponentStructure.js';
import BookletPreviewByComponent from '../components/Products/ProductsByComponents/Preview/BookletPreviewByComponent.js';
import LooseSheetComponentStructure from '../components/Products/ProductsByComponents/Structure/LooseSheetComponentStructure.js';
import LooseSheetPreviewByComponent from '../components/Products/ProductsByComponents/Preview/LooseSheetPreviewByComponent.js';
import ProductComponentStructure from '../components/Products/ProductsByComponents/Structure/ProductComponentStructure.js';
import ProductPreviewByComponent from '../components/Products/ProductsByComponents/Preview/ProductPreviewByComponent.js';
import PosterComponentStructure from '../components/Products/ProductsByComponents/Structure/PosterComponentStructure.js';
import PosterPreviewByComponent from '../components/Products/ProductsByComponents/Preview/PosterPreviewByComponent.js';
import PackagingPreviewByComponent from '../components/Products/ProductsByComponents/Preview/PackagingPreviewByComponent.js';
import ClusteredProductComponentStructure from '../components/Products/ProductsByComponents/Structure/ClusteredProductComponentStructure.js';
import PackagingComponentStructure from '../components/Products/ProductsByComponents/Structure/PackagingComponentStructure.js';
import ServerSidePreviewByComponent from '../components/Products/ProductsByComponents/Preview/ServerSidePreviewByComponent.js';
import CSVComponentStructure from '../components/Products/ProductsByComponents/Structure/CSVComponentStructure.js';
import APIComponentStructure from '../components/Products/ProductsByComponents/Structure/APIComponentStructure.js';
import CustomFieldSelector from '../components/Products/ProductsByComponents/CustomFieldSelector.js';

const validationSchema = Yup.object().shape({
    code: Yup.string().required('Code is required'),
    name: Yup.string().required('Name is required'),
});
const ProductsByComponents = (props) => {
    const { userId, role, components, dynamicPriceData, pushProducts, fetchComponentsType, fetchComponents, marginsData, avaiSources, rateMYRtoSGD } = props;
    const [ message, setMessage ] = useState('');
    const [ color, setColor ] = useState('');
    const [ isOpen, setIsOpen ] = useState(false);
    const [ supportedSource, setSupportedSource ] = useState([]);
    const [ productGroups, setProductGroups ] = useState([]);
    const [ minQuantity, setMinQuantity ] = useState(1);
    const [ existingProductId, setExistingProductId ] = useState();
    const [ existingProduct, setExistingProduct ] = useState();
    
    const { search } = useLocation();

    const {
        isOpen: modalIsOpen,
        title: modalTitle,
        content: modalContent,
        callback: modalCallback,

        actions: modalActions,
    } = useModal();

    useEffect(() => {
        const params = new URLSearchParams(search);
        setExistingProductId(params.get('id'));
        fetchData();
    }, [props.userId, props.fetchComponentsType, props.fetchComponents]);

    useEffect(() => {
        if(userId && existingProductId) {
            client.authenticate()
                .then((auth)=>{
                    return client.service('products').get(existingProductId);
                })
                .then((res)=>{
                    setExistingProduct(res);
                })
                .catch((err)=>{
                    if(err.name === 'NotAuthenticated'){
                        notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                    }else{
                        notificationOpen(true, 'danger', err.message);
                    }
                });
        }
    }, [existingProductId]);

    useEffect(() => {
        if(userId) {
            fetchData();
        }
    }, [userId]);

    useEffect(() => {
        if (existingProduct) {
            const quantityComponent = existingProduct.materialComponents.find((x) => x.componentTypeName === 'Quantity');
            if(quantityComponent) {
                client.authenticate()
                    .then(()=>{
                        return client.service('components').get(quantityComponent.componentId);
                    })
                    .then((res)=>{
                        if(res.data.quantity.isCustom) {
                            const quantites = getQuantities(res.data.quantity.isCustom, res);
                            setMinQuantity(quantites[0].value);
                        } else {
                            setMinQuantity(res.data.quantity.min);
                        }
                    })
                    .catch((err)=>{
                        if(err.name === 'NotAuthenticated'){
                            notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                        }else{
                            notificationOpen(true, 'danger', err.message);
                        }
                    });
            }
        }

    }, [existingProduct]);

    useEffect(() => {
        if(userId) {
            setSupportedSource(env.componentSources);
        }
    }, []);
  
    const notificationOpen = (bool, color, message) => {
        modalActions.setIsOpen(bool);
        modalActions.setTitle('Alert');
        modalActions.setContent(message);
        modalActions.setCallback(null);
    };

    const fetchData = () => {
        client.authenticate()
            .then((auth)=>{
                return client.service('product-groups').find({
                    query: {
                        userIds: {
                            $in: userId
                        }
                    }
                });
            })
            .then((res)=>{
                const groups = [];
                if(props.role === 'superadmin'){
                    groups.push({
                        value: 'api',
                        display: 'Excard (API) - West'
                    });
                    groups.push({
                        value: 'apiEast',
                        display: 'Excard East (Api)'
                    });
                    groups.push({
                        value: 'apiSg',
                        display: 'Excard SG (Api)'
                    });
                }
                // if (avaiSources.api) {
                //     groups.push({
                //         value: 'api',
                //         display: 'Excard (Api)'
                //     });
                // }
                // if (avaiSources.apiSg) {
                //     groups.push({
                //         value: 'apiSg',
                //         display: 'Excard SG (Api)'
                //     });
                // }
                // if (avaiSources.apiEast) {
                //     groups.push({
                //         value: 'apiEast',
                //         display: 'Excard East (Api)'
                //     });
                // }
                groups.push(...res.data.map((x) => {
                    return {
                        value: x._id,
                        display: x.groupName
                    };
                }));
                setProductGroups(groups);
            })
            .then(()=>{
                return client.service('componentsType').find({
                    query: {
                        $limit: 1000,
                        $or: [{ userId: new ObjectId(userId) }, { userId: null }],
                        $sort: { createdAt: 1 },
                    }
                });
            })
            .then((res) => {
                fetchComponentsType(res.data);
            })
            .catch((err)=>{
                if(err.name === 'NotAuthenticated'){
                    notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                }else{
                    notificationOpen(true, 'danger', err.message);
                }
            });
    };

    const onSubmit = async(value) => {
        if(value.status === 1) {
            if(value.source.includes('api')) {
                if (!value.apiType && (!value.apiSource || !value.groupProduct)) {
                    modalActions.setIsOpen(true);
                    modalActions.setTitle('Missing fields');
                    modalActions.setContent('Kindly fill in all compulsory fields');
                    modalActions.setCallback(undefined);
                    return;
                }
            } else if (value.source.includes('csv')) {
                if(!value.csvFile.fileUrl) {
                    modalActions.setIsOpen(true);
                    modalActions.setTitle('Missing fields');
                    modalActions.setContent('Kindly fill in all compulsory fields');
                    modalActions.setCallback(undefined);
                    return;
                }
            }  else if (value.source.includes('poster')) {
                if(value.materialComponents.length <= 0
                || (value.printSizeComponents.length <= 0 && !value.materialComponents.every((x) => x.nestedComponents.some((n) => n.componentTypeName === 'Printing Size')))
                || (value.printCostComponents.length <= 0 && !value.materialComponents.every((x) => x.nestedComponents.some((n) => n.componentTypeName === 'Printing Cost')))
                || (value.quantityComponents.length <= 0 && !value.materialComponents.every((x) => x.nestedComponents.some((n) => n.componentTypeName === 'Quantity')))) {
                    modalActions.setIsOpen(true);
                    modalActions.setTitle('Missing fields');
                    modalActions.setContent('Kindly fill in all compulsory fields');
                    modalActions.setCallback(undefined);
                    return;
                }
            } else if (value.source.includes('loose-sheet')) {
                if (value.materialComponents.length <= 0
                || (value.printSizeComponents.length <= 0 && !value.materialComponents.every((x) => x.nestedComponents.some((n) => n.componentTypeName === 'Printing Size')))
                || (value.printCostComponents.length <= 0 && !value.materialComponents.every((x) => x.nestedComponents.some((n) => n.componentTypeName === 'Printing Cost')))
                || (value.quantityComponents.length <= 0 && !value.materialComponents.every((x) => x.nestedComponents.some((n) => n.componentTypeName === 'Quantity')))) {
                    modalActions.setIsOpen(true);
                    modalActions.setTitle('Missing fields');
                    modalActions.setContent('Kindly fill in all compulsory fields');
                    modalActions.setCallback(undefined);
                    return;
                }
            } else if (value.source.includes('booklet')) {
                if (value.isClustered && (value.clusteredProducts.length <= 0 || !value.clusterLabel)) {
                    modalActions.setIsOpen(true);
                    modalActions.setTitle('Missing fields');
                    modalActions.setContent('Kindly fill in all compulsory fields');
                    modalActions.setCallback(undefined);
                    return;
                }
                
                if (!value.isClustered && 
                    (value.bookletData.bindingTypeComponents.length <= 0
                    || value.bookletData.printSizeComponents.length <= 0
                    || value.bookletData.coverComponents.materialComponents.length <= 0
                    || value.bookletData.coverComponents.printCostComponents.length <= 0
                    || value.bookletData.contentComponents.materialComponents.length <= 0
                    || value.bookletData.contentComponents.printCostComponents.length <= 0
                    || value.bookletData.quantityComponents.length <= 0)) {
                    modalActions.setIsOpen(true);
                    modalActions.setTitle('Missing fields');
                    modalActions.setContent('Kindly fill in all compulsory fields');
                    modalActions.setCallback(undefined);
                    return;
                }
            } else if (value.source.includes('packaging')) {
                if(value.packagingComponents.length <= 0
                || (value.printSizeComponents.length <= 0 && !value.packagingComponents.every((x) => x.nestedComponents.some((n) => n.componentTypeName === 'Printing Size')))
                || (value.printCostComponents.length <= 0 && !value.packagingComponents.every((x) => x.nestedComponents.some((n) => n.componentTypeName === 'Printing Cost')))
                || (value.quantityComponents.length <= 0 && !value.packagingComponents.every((x) => x.nestedComponents.some((n) => n.componentTypeName === 'Quantity')))) {
                    modalActions.setIsOpen(true);
                    modalActions.setTitle('Missing fields');
                    modalActions.setContent('Kindly fill in all compulsory fields');
                    modalActions.setCallback(undefined);
                    return;
                }
            }
        }
        
        value.marginProfiles = value.marginProfiles.filter((x) => (x));

        if (existingProductId) {
            client.authenticate()
                .then(()=>{
                    return client.service('products').update(value._id, value);
                })
                .then((res) => {
                    modalActions.setIsOpen(true);
                    modalActions.setTitle('Product Updated!');
                    modalActions.setContent('Product has been successfully updated.');
                    modalActions.setCallback(undefined);
                    // props.history.push(`/${props.role}/`);
                })
                .catch((err)=>{
                    if(err.name === 'NotAuthenticated'){
                        notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                    }else{
                        notificationOpen(true, 'danger', err.message);
                    }
                });
        } else {
            client.authenticate()
                .then(()=>{
                    return client.service('products').create(value);
                })
                .then((res) => {
                    pushProducts(res);
                    setExistingProductId(res._id);
                    modalActions.setIsOpen(true);
                    modalActions.setTitle('Product Created!');
                    modalActions.setContent('Product has been successfully created.');
                    modalActions.setCallback(undefined);
                    props.history.push(`/${props.role}/products-create-with-components?id=${res._id}`);
                })
                .catch((err)=>{
                    if(err.name === 'NotAuthenticated'){
                        notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                    }else{
                        notificationOpen(true, 'danger', err.message);
                    }
                });
        }
    };


    const renderComponentStructure = (values, setFieldValue) => {
        if(values.source.includes('loose-sheet')) {
            return (
                <CardBody className='rounded px-4 py-3'>
                    <h3>Product Price Settings</h3>
                    <LooseSheetComponentStructure
                        userId={userId}
                        values={values}
                        setFieldValue={setFieldValue}
                        existingProduct={existingProduct}
                        marginsData={marginsData}
                        dynamicPriceData={dynamicPriceData}
                        role={role}
                        notificationOpen={notificationOpen}
                    />
                </CardBody>
            
            );
        } else if( values.source.includes('booklet')) {
            if(values.isClustered) {
                return (
                    <CardBody className='rounded px-4 py-3'>
                        <h3>Cluster Booklet Settings</h3>
                        <ClusteredProductComponentStructure
                            userId={userId}
                            values={values}
                            setFieldValue={setFieldValue}
                            existingProduct={existingProduct}
                            marginsData={marginsData}
                            dynamicPriceData={dynamicPriceData}
                            role={role}
                            notificationOpen={notificationOpen}
                        />
                    </CardBody>
                );
            } else {
                return (
                    <CardBody className='rounded px-4 py-3'>
                        <h3>Product Price Settings</h3>
                        <BookletComponentStructure
                            userId={userId}
                            values={values}
                            setFieldValue={setFieldValue}
                            existingProduct={existingProduct}
                            marginsData={marginsData}
                            dynamicPriceData={dynamicPriceData}
                            role={role}
                            notificationOpen={notificationOpen}
                        />
                    </CardBody>
                );
            }
        } else if(values.source.includes('poster')) {
            return (
                <CardBody className='rounded px-4 py-3'>
                    <h3>Product Price Settings</h3>
                    <PosterComponentStructure
                        userId={userId}
                        values={values}
                        setFieldValue={setFieldValue}
                        existingProduct={existingProduct}
                        marginsData={marginsData}
                        dynamicPriceData={dynamicPriceData}
                        role={role}
                        notificationOpen={notificationOpen}
                    />
                </CardBody>
            );
        } else if(values.source.includes('packaging')) {
            return (
                <CardBody className='rounded px-4 py-3'>
                    <h3>Product Price Settings</h3>
                    <PackagingComponentStructure
                        userId={userId}
                        values={values}
                        setFieldValue={setFieldValue}
                        existingProduct={existingProduct}
                        marginsData={marginsData}
                        dynamicPriceData={dynamicPriceData}
                        role={role}
                        notificationOpen={notificationOpen}
                    />
                </CardBody>
            );
        } else if (values.source.includes('csv')) {
            return (
                <CardBody className='rounded px-4 py-3'>
                    <h3>Product Price Settings</h3>
                    <CSVComponentStructure
                        userId={userId}
                        values={values}
                        setFieldValue={setFieldValue}
                        existingProduct={existingProduct}
                        marginsData={marginsData}
                        dynamicPriceData={dynamicPriceData}
                        role={role}
                        notificationOpen={notificationOpen}
                    />
                </CardBody>
            );
        } else if(values.source.includes('product')) {
            return (
                <CardBody className='rounded px-4 py-3'>
                    <h3>Product Price Settings</h3>
                    <ProductComponentStructure
                        userId={userId}
                        values={values}
                        setFieldValue={setFieldValue}
                        existingProduct={existingProduct}
                        marginsData={marginsData}
                        dynamicPriceData={dynamicPriceData}
                        role={role}
                        notificationOpen={notificationOpen}
                    />
                </CardBody>);
        } else {
            return (
                <CardBody className='rounded px-4 py-3'>
                    <h3>Product Price Settings</h3>
                    <APIComponentStructure
                        userId={userId}
                        values={values}
                        setFieldValue={setFieldValue}
                        existingProduct={existingProduct}
                        marginsData={marginsData}
                        dynamicPriceData={dynamicPriceData}
                        role={role}
                        notificationOpen={notificationOpen}
                        productGroups={productGroups}
                    />
                </CardBody>
            );
        }
    };

    const renderPreview = (values, setFieldValue) => {
        if(values.source.includes('loose-sheet')) {
            return (<LooseSheetPreviewByComponent
                role={role}
                values={values}
                userId={userId}
                setFieldValue={setFieldValue}
                source={values.source}
                productDynamicPriceId={values.dynamicPriceName}
                productSuperDynamicPriceId={values.superDynamicPriceId}
                marginId={values.marginName}
                marginSuperId={values.marginSuperName}
            />);
        } else if( values.source.includes('booklet')) {
            if (values.isClustered) {
                return (
                    <ServerSidePreviewByComponent
                        role={role}
                        values={values}
                        userId={userId}
                        setFieldValue={setFieldValue}
                        source={values.source}
                        productDynamicPriceId={values.dynamicPriceName}
                        productSuperDynamicPriceId={values.superDynamicPriceId}
                        marginId={values.marginName}
                        marginSuperId={values.marginSuperName}
                    />);
            } else {
                return (
                    <BookletPreviewByComponent
                        role={role}
                        values={values}
                        userId={userId}
                        setFieldValue={setFieldValue}
                        source={values.source}
                        productDynamicPriceId={values.dynamicPriceName}
                        productSuperDynamicPriceId={values.superDynamicPriceId}
                        marginId={values.marginName}
                        marginSuperId={values.marginSuperName}
                    />);
            }
        } else if(values.source.includes('poster')) {
            return (<PosterPreviewByComponent
                role={role}
                values={values}
                userId={userId}
                setFieldValue={setFieldValue}
                source={values.source}
                productDynamicPriceId={values.dynamicPriceName}
                productSuperDynamicPriceId={values.superDynamicPriceId}
                marginId={values.marginName}
                marginSuperId={values.marginSuperName}
            />);
        } else if(values.source.includes('packaging')) {
            return (<PackagingPreviewByComponent
                role={role}
                values={values}
                userId={userId}
                setFieldValue={setFieldValue}
                source={values.source}
                productDynamicPriceId={values.dynamicPriceName}
                productSuperDynamicPriceId={values.superDynamicPriceId}
                marginId={values.marginName}
                marginSuperId={values.marginSuperName}
            />);
        } else if(values.source.includes('product')) {
            return (<ProductPreviewByComponent
                role={role}
                values={values}
                userId={userId}
                setFieldValue={setFieldValue}
                source={values.source}
                productDynamicPriceId={values.dynamicPriceName}
                productSuperDynamicPriceId={values.superDynamicPriceId}
                marginId={values.marginName}
                marginSuperId={values.marginSuperName}
            />);
        } else {
            return (<ServerSidePreviewByComponent
                role={role}
                values={values}
                userId={userId}
                setFieldValue={setFieldValue}
                source={values.source}
                productDynamicPriceId={values.dynamicPriceName}
                productSuperDynamicPriceId={values.superDynamicPriceId}
                marginId={values.marginName}
                marginSuperId={values.marginSuperName}
            />
            );
        }
    };

    return (
        <>
            {/* eslint-disable-next-line no-undef */}
            {(window.self === window.top) && (
                <div className='header' style={{height: 64}}>
                    <span className='mask' style={{backgroundColor: '#fff'}} />
                </div>
            )}

            <Container fluid>
                <Row className='m-1 mt-3'>
                    <h2 className='mr-4 mb-0'>{(existingProduct) ? 'Edit Product' : 'Create Product'}</h2>
                </Row>
                <Breadcrumb
                    items={[
                        {title: 'Products', link: `${role}/index`},
                        {title: (existingProduct) ? 'Edit Product' : 'Create Product'}
                    ]}
                />
                <div className='mt-2'>
                    <Formik
                        initialValues={{
                            _id: (existingProduct) ? existingProduct._id : undefined,
                            userId,
                            rateMYRtoSGD: rateMYRtoSGD ?? 3,
                            apiSource: (existingProduct) ? existingProduct.apiSource : undefined,
                            apiType: (existingProduct) ? existingProduct.apiType : undefined,
                            group: (existingProduct) ? existingProduct.group : undefined,
                            groupProduct: (existingProduct) ? existingProduct.groupProduct : undefined,
                            isClustered: (existingProduct) ? existingProduct.isClustered : false,
                            clusteredProducts: (existingProduct) ? existingProduct.clusteredProducts : [],
                            clusterLabel: (existingProduct) ? existingProduct.clusterLabel : '',
                            createdBy: userId,
                            code: (existingProduct) ? existingProduct.code : '',
                            name: (existingProduct) ? existingProduct.name : '',    
                            description: (existingProduct) ? existingProduct.description : '',
                            isComponentBased: true,
                            source: (existingProduct) ? existingProduct.source : 'api',
                            packagingComponents: (existingProduct?.packagingComponents) || [],
                            materialComponents: (existingProduct) ? existingProduct.materialComponents : [],
                            modelComponents: (existingProduct) ? existingProduct.modelComponents : [],
                            printCostComponents: (existingProduct) ? existingProduct.printCostComponents : [],
                            printSizeComponents: (existingProduct) ? existingProduct.printSizeComponents : [],
                            quantityComponents: (existingProduct) ? existingProduct.quantityComponents : [],
                            additionalComponents: (existingProduct) ? existingProduct.additionalComponents : [],
                            productionComponents: (existingProduct?.productionComponents) ? existingProduct.productionComponents : [],
                            artworkComponents: (existingProduct?.artworkComponents) ? existingProduct.artworkComponents : [],
                            fileStorageComponents: (existingProduct?.fileStorageComponents) ? existingProduct.fileStorageComponents : [],
                            customFields: (existingProduct?.customFields) ? existingProduct.customFields : [],
                            marginName: (existingProduct) ? existingProduct.marginName : undefined,
                            marginSuperName: (existingProduct) ? existingProduct.marginSuperName : undefined,
                            dynamicPriceName: (existingProduct) ? existingProduct.dynamicPriceName : undefined,
                            superDynamicPriceId: (existingProduct) ? existingProduct.superDynamicPriceId : undefined,
                            csvFile: (existingProduct) ? existingProduct.csvFile : {
                                isSingleQuantity: false,
                                originalFileName: '',
                                fileName: '',
                                fileUrl: '',  
                            },
                            marginProfiles: (existingProduct?.marginProfiles) ? existingProduct.marginProfiles : [],
                            preview: {
                                bindingType: '',
                                coverMaterial: '',
                                contentMaterial: '',
                                model: (existingProduct) ? existingProduct?.modelComponents?.find((x) => x.componentTypeName === 'Model')?.componentId : '',
                                material: (existingProduct) ? existingProduct.materialComponents.find((x) => x.componentTypeName === 'Material')?.componentId : '',
                                pp: '',
                                printingSize: '',
                                size: {
                                    customLength: 0,
                                    customWidth: 0,
                                    customHeight: 0,
                                },
                                printCost: '',
                                printCosts: [],
                                coverPrintCosts: [],
                                contentPrintCosts: [],
                                quantityId: '',
                                productionTimelineComponentId: '',
                                artworkId: '',
                                quantity: minQuantity,
                                variableQuantity: [],
                                artwork: '',
                                productionTimeline: '',
                                options: [],
                                coverOptions: [],
                                contentOptions: [],
                            },
                            additionalDataPrices: [],
                            bookletData: (existingProduct) ? existingProduct.bookletData : {
                                bindingTypeComponents: [],
                                coverComponents: {
                                    materialComponents: [],
                                    printCostComponents: [],
                                    additionalComponents: [],
                                },
                                contentComponents: {
                                    materialComponents: [],
                                    printCostComponents: [],
                                    additionalComponents: [],
                                },
                                printSizeComponents: [],
                                quantityComponents: [],
                                additionalComponents: [],
                                coverToContentPairing: [],
                            },
                            quantity: 1,
                            width: 0,
                            height: 0,
                            additionalData: [],
                            additionOption: [],
                            roundNumbers: (existingProduct) ? existingProduct.roundNumbers : '',
                        }}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                        enableReinitialize={true}
                    >
                        {formik => {
                            const {
                                values,
                                touched,
                                errors,
                                handleChange,
                                handleBlur,
                                setFieldValue,
                                handleSubmit,
                            } = formik;

                            return (
                                <Form role='form'
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}
                                >
                                    <div className='modal-body p-0'>
                                        <Row>
                                            <Col md='12'>
                                                <Card className='mb-4'>
                                                    <CardBody className='rounded'>
                                                        <div className='px-3 mb-4 border rounded'>
                                                            <Row className='p-2 px-4'>
                                                                <h4 className='m-0'>Product Information</h4>
                                                            </Row>
                                                            <Row className='bg-secondary p-2 py-4 rounded-bottom'>
                                                                <Col md='2'>
                                                                    <h5 className='mb-1'>Data Source</h5>
                                                                    <Dropdown
                                                                        name={'source'}
                                                                        setFieldValue={setFieldValue}
                                                                        values={supportedSource}
                                                                        width={'100%'}
                                                                        disabled={(!!values._id)}
                                                                    />
                                                                    {values.source === 'booklet' && (
                                                                        <label className='d-flex align-items-center mb-0 mr-2'>
                                                                            <Field style={{marginRight: '5px'}} type='checkbox' name='isClustered'/>
                                                                            <small className='mr-1 mb-0 w-10'>Cluster</small>
                                                                        </label>
                                                                    )}
                                                                </Col>
                                                                <Col md='2'>
                                                                    <h5 className='mb-1'>Code</h5>
                                                                    <Field 
                                                                        className='form-control form-control-sm form-control-alternative'
                                                                        placeholder='Code'
                                                                        type='text'
                                                                        name={'code'}
                                                                        maxlength='8'
                                                                    />
                                                                    {errors.code && touched.code ? (
                                                                        <small className='text-danger xs'>{errors.code}</small>
                                                                    ) : null}
                                                                </Col>
                                                                <Col md='3'>
                                                                    <h5 className='mb-1'>Product Name</h5>
                                                                    <Field 
                                                                        className='form-control form-control-sm form-control-alternative'
                                                                        placeholder='Name'
                                                                        type='text'
                                                                        name={'name'}
                                                                    />
                                                                    {errors.name && touched.name ? (
                                                                        <small className='text-danger xs'>{errors.name}</small>
                                                                    ) : null}
                                                                </Col>
                                                                <Col md='3'>
                                                                    <h5 className='mb-1'>Descriptions</h5>

                                                                    <Field 
                                                                        className='form-control form-control-sm form-control-alternative'
                                                                        placeholder='Description'
                                                                        type='textarea'
                                                                        name={'description'}
                                                                    />
                                                                </Col>
                                                                <Col md='2'>
                                                                    <h5 className='mb-1'>Rounding Price</h5>
                                                                    <Dropdown
                                                                        name={'roundNumbers'}
                                                                        setFieldValue={setFieldValue}
                                                                        values={[
                                                                            {
                                                                                display: 'Not Required',
                                                                                value: ''
                                                                            },
                                                                            {
                                                                                display: 'Round Up',
                                                                                value: 'roundUp'
                                                                            },
                                                                            {
                                                                                display: 'Round Down',
                                                                                value: 'roundDown'
                                                                            },
                                                                        ]}
                                                                        width={'100%'}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            
                                                        </div>

                                                        <Col className='mb-3 p-0'>
                                                            <DragDropContext
                                                                onDragEnd={(result) => {
                                                                    if (!result.destination) {
                                                                        return;
                                                                    }

                                                                    const source = cloneDeep(eval('values.' + result.source.droppableId));
                                                                    const destination = cloneDeep(eval('values.' + result.destination.droppableId));

                                                                    if (source[result.source.index].componentTypeName === 'Material' && result.destination.droppableId.includes('[')) {
                                                                        return;
                                                                    }

                                                                    if(result.destination.droppableId === result.source.droppableId) {
                                                                        const clone = cloneDeep(eval('values.' + result.destination.droppableId));
                                                                        const temp = clone[result.source.index];
                                                                        clone[result.source.index] = clone[result.destination.index];
                                                                        clone[result.destination.index] = temp;

                                                                        setFieldValue(`${result.destination.droppableId}`, clone);
                                                                        return;
                                                                    }
                                                                    // else {
                                                                    //     destination.splice(result.destination.index, 0, source[result.source.index]);
                                                                    //     source.splice(result.source.index, 1);

                                                                    //     setFieldValue(`${result.source.droppableId}`, source);
                                                                    //     setFieldValue(`${result.destination.droppableId}`, destination);
                                                                    // }
                                                                }}>
                                                                <Row className='m-0 align-items-top justify-content-between'>
                                                                    <Col md='8' className='p-0'>
                                                                        <Card className='mb-4 h-100'>
                                                                            {renderComponentStructure(values, setFieldValue)}
                                                                        </Card>
                                                                    </Col>
                                                                    <Col md='4'>
                                                                        {renderPreview(values, setFieldValue)}
                                                                    </Col>
                                                                </Row>
                                                            </DragDropContext>
                                                        </Col>
                                                        
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                           
                                        </Row>
                                    </div>
                                    <div className='modal-footer'>
                                        <Button
                                            color='secondary'
                                            data-dismiss='modal'
                                            type='button'
                                            onClick={() => {
                                                props.history.push(`/${props.role}/components`);
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            color='secondary' 
                                            type='submit'
                                            onClick={() => setFieldValue('status', 0)}
                                        >
                                            Save as Draft
                                        </Button>
                                        <Button
                                            color='primary' 
                                            type='submit'
                                            onClick={() => setFieldValue('status', 1)}
                                        >
                                            Save as Active
                                        </Button>
                                    </div>

                                    <CustomFieldSelector
                                        userId={userId}
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        notificationOpen={notificationOpen}
                                    />
                                </Form>
                            );
                        }}
                        
                    </Formik>
                </div>
                <Notifications 
                    isOpen={isOpen} 
                    handleOpen={notificationOpen} 
                    message={message} 
                    color={color}
                />
                <ModalWithCallback
                    isOpen={modalIsOpen}
                    toggle={() => modalActions.toggle()}
                    title={modalTitle}
                    content={modalContent}
                    callback={modalCallback}
                />
            </Container>
        </>
    );
};


const mapStateToProps = state => ({
    userId: state.role.details.user?state.role.details.user._id:'',
    avaiSources: state.role.details.user.avaiSources,
    currency: state.role.details.user?state.role.details.user.currency?getSymbolFromCurrency(state.role.details.user.currency):'rm':'rm',
    componentsTypeData: state.componentsType.data,
    components: state.components.data,
    role: state.role.details.user?state.role.details.user.role:'',
    userInfo: state.role.details.user?state.role.details.user:{},
    marginsData: state.margins.data,
    marginProfilesData: state.marginProfiles.data,
    dynamicPriceData: state.dynamicPrice.data,
    componentTypeColors: state.componentsType.componentTypeColors,
    rateMYRtoSGD: state?.company?.data?.rateMYRtoSGD?state.company.data.rateMYRtoSGD:4.13,
});

const mapDispatchToProps = {
    fetchComponents: fetchComponents,
    pushComponents: pushComponents,
    updateComponents: updateComponents,
    removeComponents: removeComponents,
    fetchComponentsType: fetchComponentsType,
    pushProducts: pushProducts,
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductsByComponents);